<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
		 <!-- 	<div slot="buttonGroup" class="h-handle-button">
				<div class="h-b">
					<el-button type="primary" size="small" @click="addCustomerInfo">新增</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="setStatus">停用</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="setStatus">启用</el-button>
				</div>
			</div> -->
			<p slot="elList">
				<el-table ref="customerInfoListTable" :data="dataSource.Result" border
					 highlight-current-row>
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)"
						:fixed="index==0&&(!config||!config.isDetailDisplay)"
						:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
						v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<span v-if="col.FieldName==='CustomerCode'"><span type="text" @click="rowClick(scope.row)"
									style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>
<script>
	export default {
		mounted() {
			this.initialize();
		},
		data() {
			return {
				isPassValidate: true,
				queryParam: {
					PageIndex: 1,
					PageSize: 10
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				multipleSelection: []
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
			},
		
			option: {}
		},
		methods: {
			reloadPageList: function() {
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			onPageChange(param) {
				var _this = this;
				this.queryParam = param;
				this.$ajax.query("omsapi/stockLotManage/searchlotlist", "post", param, data => {
					_this.dataSource = data;
				});
			},
			onDataSourceChange(ds) {
				var _this = this;

				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			rowClick(row) {
				this.$ajax.send("omsapi/stockLotManage/findList?customerCode="+row.CustomerCode, "get", {}, (data) => {
					this.Event.$emit("clearEditCustomerInfoForm");
					var da={};
					da.tableData=data.Result;
					this.onChangeEditDataSource(da);
				});
			},
			onChangeEditDataSource(data) {
				this.config.isDetailDisplay = true;
				this.$emit("onChangeEditDataSource", data);
			},
		}
	}
</script>
<style>
</style>